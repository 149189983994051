


















div {
  color: #B1BACB;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 16px;
  display: flex;
}
